import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action, computed } from "@ember/object";
import { inject as service } from "@ember/service";
import { Promise } from "rsvp";
import ChangeTags from "discourse/components/bulk-actions/change-tags";
import DButton from "discourse/components/d-button";
import DModal from "discourse/components/d-modal";
import RadioButton from "discourse/components/radio-button";
import { topicLevels } from "discourse/lib/notification-levels";
import Topic from "discourse/models/topic";
import htmlSafe from "discourse-common/helpers/html-safe";
import i18n from "discourse-common/helpers/i18n";
import CategoryChooser from "select-kit/components/category-chooser";
import TagChooser from "select-kit/components/tag-chooser";
export default class BulkTopicActions extends Component {
    @service
    router;
    @tracked
    activeComponent = null;
    @tracked
    tags = [];
    @tracked
    categoryId;
    notificationLevelId = null;
    constructor(){
        super(...arguments);
        if (this.args.model.initialAction === "set-component") {
            this.setComponent(ChangeTags);
        }
    }
    async perform(operation1) {
        this.loading = true;
        if (this.args.model.bulkSelectHelper.selected.length > 20) {
            this.showProgress = true;
        }
        try {
            return this._processChunks(operation1);
        } catch  {
            this.dialog.alert(i18n("generic_error"));
        } finally{
            this.loading = false;
            this.processedTopicCount = 0;
            this.showProgress = false;
        }
    }
    _generateTopicChunks(allTopics1) {
        let startIndex1 = 0;
        const chunkSize1 = 30;
        const chunks1 = [];
        while(startIndex1 < allTopics1.length){
            chunks1.push(allTopics1.slice(startIndex1, startIndex1 + chunkSize1));
            startIndex1 += chunkSize1;
        }
        return chunks1;
    }
    _processChunks(operation1) {
        const allTopics1 = this.args.model.bulkSelectHelper.selected;
        const topicChunks1 = this._generateTopicChunks(allTopics1);
        const topicIds1 = [];
        const options1 = {};
        if (this.args.model.allowSilent === true) {
            options1.silent = true;
        }
        const tasks1 = topicChunks1.map((topics1)=>async ()=>{
                const result1 = await Topic.bulkOperation(topics1, operation1, options1);
                this.processedTopicCount += topics1.length;
                return result1;
            });
        return new Promise((resolve1, reject1)=>{
            const resolveNextTask1 = async ()=>{
                if (tasks1.length === 0) {
                    const topics1 = topicIds1.map((id1)=>allTopics1.findBy("id", id1));
                    return resolve1(topics1);
                }
                const task1 = tasks1.shift();
                try {
                    const result1 = await task1();
                    if (result1?.topic_ids) {
                        topicIds1.push(...result1.topic_ids);
                    }
                    resolveNextTask1();
                } catch  {
                    reject1();
                }
            };
            resolveNextTask1();
        });
    }
    @action
    setComponent(component1) {
        this.activeComponent = component1;
    }
    @action
    performAction() {
        switch(this.args.model.action){
            case "close":
                this.forEachPerformed({
                    type: "close"
                }, (t1)=>t1.set("closed", true));
                break;
            case "archive":
                this.forEachPerformed({
                    type: "archive"
                }, (t1)=>t1.set("archived", true));
                break;
            case "unlist":
                this.forEachPerformed({
                    type: "unlist"
                }, (t1)=>t1.set("unlisted", true));
                break;
            case "relist":
                this.forEachPerformed({
                    type: "relist"
                }, (t1)=>t1.set("unlisted", false));
                break;
            case "append-tags":
                this.performAndRefresh({
                    type: "append_tags",
                    tags: this.tags
                });
                break;
            case "replace-tags":
                this.performAndRefresh({
                    type: "change_tags",
                    tags: this.tags
                });
                break;
            case "remove-tags":
                this.performAndRefresh({
                    type: "remove_tags"
                });
                break;
            case "delete":
                this.performAndRefresh({
                    type: "delete"
                });
                break;
            case "reset-bump-dates":
                this.performAndRefresh({
                    type: "reset_bump_dates"
                });
                break;
            case "defer":
                this.performAndRefresh({
                    type: "destroy_post_timing"
                });
                break;
            case "update-notifications":
                this.performAndRefresh({
                    type: "change_notification_level",
                    notification_level_id: this.notificationLevelId
                });
                break;
            case "update-category":
                this.forEachPerformed({
                    type: "change_category",
                    category_id: this.categoryId
                }, (t1)=>t1.set("category_id", this.categoryId));
                break;
        }
    }
    @action
    async forEachPerformed(operation1, cb1) {
        const topics1 = await this.perform(operation1);
        if (topics1) {
            topics1.forEach(cb1);
            this.args.model.refreshClosure?.();
            this.args.closeModal();
            this.args.model.bulkSelectHelper.toggleBulkSelect();
        }
    }
    @action
    async performAndRefresh(operation1) {
        await this.perform(operation1);
        this.args.model.refreshClosure?.();
        this.args.closeModal();
        this.args.model.bulkSelectHelper.toggleBulkSelect();
    }
    @computed("action")
    get isTagAction() {
        return (this.args.model.action === "append-tags" || this.args.model.action === "replace-tags");
    }
    @computed("action")
    get isNotificationAction() {
        return this.args.model.action === "update-notifications";
    }
    @computed("action")
    get isCategoryAction() {
        return this.args.model.action === "update-category";
    }
    get notificationLevels() {
        return topicLevels.map((level1)=>({
                id: level1.id.toString(),
                name: i18n(`topic.notifications.${level1.key}.title`),
                description: i18n(`topic.notifications.${level1.key}.description`)
            }));
    }
    @action
    onCategoryChange(categoryId1) {
        this.categoryId = categoryId1;
    }
    static{
        template(`
    <DModal
      @title={{@model.title}}
      @closeModal={{@closeModal}}
      class="topic-bulk-actions-modal -large"
    >
      <:body>
        <div>
          {{htmlSafe
            (i18n
              "topics.bulk.selected"
              count=@model.bulkSelectHelper.selected.length
            )
          }}
        </div>

        {{#if this.isCategoryAction}}
          <p>
            <CategoryChooser
              @value={{this.categoryId}}
              @onChange={{this.onCategoryChange}}
            />
          </p>
        {{/if}}

        {{#if this.isNotificationAction}}
          <div class="bulk-notification-list">
            {{#each this.notificationLevels as |level|}}
              <div class="controls">
                <label class="radio notification-level-radio checkbox-label">
                  <RadioButton
                    @value={{level.id}}
                    @name="notification_level"
                    @selection={{this.notificationLevelId}}
                  />
                  <strong>{{level.name}}</strong>
                  <div class="description">{{htmlSafe level.description}}</div>
                </label>
              </div>
            {{/each}}
          </div>
        {{/if}}

        {{#if this.isTagAction}}
          <p><TagChooser @tags={{this.tags}} @categoryId={{@categoryId}} /></p>
        {{/if}}
      </:body>

      <:footer>
        {{#if @model.allowSilent}}
          <div class="topic-bulk-actions-options">
            <label
              for="topic-bulk-action-options__silent"
              class="checkbox-label"
            >
              <input
                class=""
                id="topic-bulk-action-options__silent"
                type="checkbox"
              />{{i18n "topics.bulk.silent"}}</label>
          </div>
        {{/if}}
        <DButton
          @action={{this.performAction}}
          @icon="check"
          @label="topics.bulk.confirm"
          id="bulk-topics-confirm"
          class="btn-primary"
        />
      </:footer>

    </DModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
