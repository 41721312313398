import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { Input } from "@ember/component";
import { concat, fn, get } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import EditNavigationMenuModal from "discourse/components/sidebar/edit-navigation-menu/modal";
import borderColor from "discourse/helpers/border-color";
import categoryBadge from "discourse/helpers/category-badge";
import dirSpan from "discourse/helpers/dir-span";
import loadingSpinner from "discourse/helpers/loading-spinner";
import { popupAjaxError } from "discourse/lib/ajax-error";
import Category from "discourse/models/category";
import { INPUT_DELAY } from "discourse-common/config/environment";
import i18n from "discourse-common/helpers/i18n";
import discourseDebounce from "discourse-common/lib/debounce";
import gt from "truth-helpers/helpers/gt";
import includes from "truth-helpers/helpers/includes";
import not from "truth-helpers/helpers/not";
// Given a list, break into chunks starting a new chunk whenever the predicate
// is true for an element.
function splitWhere(elements1, f1) {
    return elements1.reduce((acc1, el1, i1)=>{
        if (i1 === 0 || f1(el1)) {
            acc1.push([]);
        }
        acc1[acc1.length - 1].push(el1);
        return acc1;
    }, []);
}
function findAncestors(categories1) {
    let categoriesToCheck1 = categories1;
    const ancestors1 = [];
    for(let i1 = 0; i1 < 3; i1++){
        categoriesToCheck1 = categoriesToCheck1.map((c1)=>Category.findById(c1.parent_category_id)).filter(Boolean).uniqBy((c1)=>c1.id);
        ancestors1.push(...categoriesToCheck1);
    }
    return ancestors1;
}
export default class extends Component {
    @service
    currentUser;
    @service
    site;
    @service
    siteSettings;
    @tracked
    initialLoad = true;
    @tracked
    filteredCategoriesGroupings = [];
    @tracked
    filteredCategoryIds = [];
    @tracked
    selectedSidebarCategoryIds = [
        ...this.currentUser.sidebar_category_ids
    ];
    constructor(){
        super(...arguments);
        this.processing = false;
        this.setFilterAndMode("", "everything");
    }
    setFilteredCategories(categories1) {
        const ancestors1 = findAncestors(categories1);
        const allCategories1 = categories1.concat(ancestors1).uniqBy((c1)=>c1.id);
        if (this.siteSettings.fixed_category_positions) {
            allCategories1.sort((a1, b1)=>a1.position - b1.position);
        }
        this.filteredCategoriesGroupings = splitWhere(Category.sortCategories(allCategories1), (category1)=>category1.parent_category_id === undefined);
        this.filteredCategoryIds = categories1.map((c1)=>c1.id);
    }
    async searchCategories(filter1, mode1) {
        if (filter1 === "" && mode1 === "only-selected") {
            this.setFilteredCategories(await Category.asyncFindByIds(this.selectedSidebarCategoryIds));
        } else {
            const { categories: categories1 } = await Category.asyncSearch(filter1, {
                includeAncestors: true,
                includeUncategorized: false
            });
            const filteredFetchedCategories1 = categories1.filter((c1)=>{
                switch(mode1){
                    case "everything":
                        return true;
                    case "only-selected":
                        return this.selectedSidebarCategoryIds.includes(c1.id);
                    case "only-unselected":
                        return !this.selectedSidebarCategoryIds.includes(c1.id);
                }
            });
            this.setFilteredCategories(filteredFetchedCategories1);
        }
    }
    async setFilterAndMode(newFilter1, newMode1) {
        this.filter = newFilter1;
        this.mode = newMode1;
        if (!this.processing) {
            this.processing = true;
            try {
                while(true){
                    const filter1 = this.filter;
                    const mode1 = this.mode;
                    await this.searchCategories(filter1, mode1);
                    this.initialLoad = false;
                    if (filter1 === this.filter && mode1 === this.mode) {
                        break;
                    }
                }
            } finally{
                this.processing = false;
            }
        }
    }
    debouncedSetFilterAndMode(filter1, mode1) {
        discourseDebounce(this, this.setFilterAndMode, filter1, mode1, INPUT_DELAY);
    }
    @action
    resetFilter() {
        this.debouncedSetFilterAndMode(this.filter, "everything");
    }
    @action
    filterSelected() {
        this.debouncedSetFilterAndMode(this.filter, "only-selected");
    }
    @action
    filterUnselected() {
        this.debouncedSetFilterAndMode(this.filter, "only-unselected");
    }
    @action
    onFilterInput(filter1) {
        this.debouncedSetFilterAndMode(filter1.toLowerCase().trim(), this.mode);
    }
    @action
    deselectAll() {
        this.selectedSidebarCategoryIds.clear();
    }
    @action
    toggleCategory(categoryId1) {
        if (this.selectedSidebarCategoryIds.includes(categoryId1)) {
            this.selectedSidebarCategoryIds.removeObject(categoryId1);
        } else {
            this.selectedSidebarCategoryIds.addObject(categoryId1);
        }
    }
    @action
    resetToDefaults() {
        this.selectedSidebarCategoryIds = this.siteSettings.default_navigation_menu_categories.split("|").map((id1)=>parseInt(id1, 10));
    }
    @action
    save() {
        this.saving = true;
        const initialSidebarCategoryIds1 = this.currentUser.sidebar_category_ids;
        this.currentUser.set("sidebar_category_ids", this.selectedSidebarCategoryIds);
        this.currentUser.save([
            "sidebar_category_ids"
        ]).then(()=>{
            this.args.closeModal();
        }).catch((error1)=>{
            this.currentUser.set("sidebar_category_ids", initialSidebarCategoryIds1);
            popupAjaxError(error1);
        }).finally(()=>{
            this.saving = false;
        });
    }
    static{
        template(`
    <EditNavigationMenuModal
      @title="sidebar.categories_form_modal.title"
      @disableSaveButton={{this.saving}}
      @save={{this.save}}
      @showResetDefaultsButton={{gt
        this.siteSettings.default_navigation_menu_categories.length
        0
      }}
      @resetToDefaults={{this.resetToDefaults}}
      @deselectAll={{this.deselectAll}}
      @deselectAllText={{i18n "sidebar.categories_form_modal.subtitle.text"}}
      @inputFilterPlaceholder={{i18n
        "sidebar.categories_form_modal.filter_placeholder"
      }}
      @onFilterInput={{this.onFilterInput}}
      @resetFilter={{this.resetFilter}}
      @filterSelected={{this.filterSelected}}
      @filterUnselected={{this.filterUnselected}}
      @closeModal={{@closeModal}}
      class="sidebar__edit-navigation-menu__categories-modal"
    >
      <form class="sidebar-categories-form">
        {{#if this.initialLoad}}
          <div class="sidebar-categories-form__loading">
            {{loadingSpinner size="small"}}
          </div>
        {{else if (gt this.filteredCategoriesGroupings.length 0)}}
          {{#each this.filteredCategoriesGroupings as |categories|}}
            <div
              class="sidebar-categories-form__row"
              style={{borderColor (get categories "0.color") "left"}}
            >

              {{#each categories as |category|}}
                <div
                  class="sidebar-categories-form__category-row"
                  data-category-id={{category.id}}
                  data-category-level={{category.level}}
                >
                  <label
                    class="sidebar-categories-form__category-label"
                    for={{concat
                      "sidebar-categories-form__input--"
                      category.id
                    }}
                  >
                    <div class="sidebar-categories-form__category-wrapper">
                      <div class="sidebar-categories-form__category-badge">
                        {{categoryBadge category}}
                      </div>
                      {{#unless category.parentCategory}}
                        <div
                          class="sidebar-categories-form__category-description"
                        >
                          {{dirSpan
                            category.description_excerpt
                            htmlSafe="true"
                          }}
                        </div>
                      {{/unless}}
                    </div>

                    <Input
                      id={{concat
                        "sidebar-categories-form__input--"
                        category.id
                      }}
                      class="sidebar-categories-form__input"
                      @type="checkbox"
                      @checked={{includes
                        this.selectedSidebarCategoryIds
                        category.id
                      }}
                      disabled={{not
                        (includes this.filteredCategoryIds category.id)
                      }}
                      {{on "click" (fn this.toggleCategory category.id)}}
                    />
                  </label>
                </div>
              {{/each}}
            </div>
          {{/each}}
        {{else}}
          <div class="sidebar-categories-form__no-categories">
            {{i18n "sidebar.categories_form_modal.no_categories"}}
          </div>
        {{/if}}
      </form>
    </EditNavigationMenuModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
